import Landing from './Landing/Landing';
import WebApp from './WebApp/WebApp';
import TempRegister from './temp/TempRegister'
import Onboarding from './WebApp/components/Onboarding';
import WebAppNanny from './WebApp/WebAppNanny';
import TermsAndConditions from './Landing/TermsAndConditions';
import NannyProfile from './WebApp/components/NannyProfile';

import { useAuthContext } from './hooks/useAuthContext';

import { BrowserRouter,Route, Routes} from 'react-router-dom';
import FAQs from './Landing/FAQs';
import WantToBeNanny from './Landing/WantToBeNanny';
import PrivacyNotice from './Landing/PrivacyNotice';
import ServiceView from './WebApp/screens/ServiceView';

function UserRouter () {
  const {user} = useAuthContext();

  if (user?.claims?.role === 'user' && user?.claims?.onboarding === false) {
    return <Onboarding />
  } else if (user?.claims?.role === 'user' && user?.claims?.onboarding === true) {
    return <WebApp />
  } else if (user?.claims?.role === 'nanny') { // add this new condition
    return <WebAppNanny/>
  } else {
    return <Landing />
  }

}


function App() {
  const { user } = useAuthContext();
  console.log(user?.claims);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UserRouter/>} />
        <Route path="/registro-nanny" element={<TempRegister />} />
        <Route path='/webapp' element={<WebApp />}/>
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/quiero-ser-nanny" element={<WantToBeNanny />} />
        <Route path="/service/:serviceId/:firstView" element={<ServiceView />}/>
        {/* TODO: Make service a protected route */}
        <Route path="/profile/:uid" element={<NannyProfile />} />
        {/* FIXME: Protect profile route */}
      </Routes>
    </BrowserRouter>
  );
}





export default App;
