import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
  } from "@mui/material";
  import React, { useState } from "react";
  import MenuIcon from "@mui/icons-material/Menu";
  import HomeIcon from "@mui/icons-material/Home";
  import ChildCareIcon from "@mui/icons-material/ChildCare";
  import PersonIcon from "@mui/icons-material/Person";
  import Logo from '../assets/img/Logo_tpb.png';
  
  export default function MenuDrawer() {
    const [open, setOpen] = useState(false);
    //TODO: Conditional rendering for the menu items depending on the user role
    const toggleDrawer = (event) => {
      // Check if the click is on the overlay or key press is escape, then close the drawer
      if (
        event.type === 'keydown' && 
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setOpen(!open);
    };
  
    return (
      <div>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          PaperProps={{
            sx: {
              width: 250, // Set a fixed width for the drawer
              backgroundColor: "rgb(249, 245, 240)",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: '#393d72',
              padding: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70px', // Limiting the height of the logo block
              overflow: 'hidden',
            }}
          >
            <img src={Logo} alt="Logo" style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </Box>
          <List>
            {/* List items */}
            <ListItem>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={"Mis Nannies"} />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Mis Domicilios"} />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ minWidth: 30 }}>
                <ChildCareIcon />
              </ListItemIcon>
              <ListItemText primary={"Mis Pequeñ@s"} />
            </ListItem>
          </List>
        </Drawer>
      </div>
    );
  }
  