/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Button,
  Divider,
  Chip,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

//Icons
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
//Functions
import { functions, storage, db } from "../firebase/config";
import { getFunctions, httpsCallable } from "firebase/functions";
// Storage
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
//Database
import { updateDoc, doc } from "firebase/firestore";
// Function to call
const funtions = getFunctions();
const createNanny = httpsCallable(funtions, "createNanny");
const sendEmail = httpsCallable(funtions, "sendEmails");

const TempForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName1, setLastName1] = useState("");
  const [lastName2, setLastName2] = useState("");
  const [nickName, setNickName] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [dateError, setDateError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [zipCodeError, setZipCodeError] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [emergencyContactPhoneError, setEmergencyContactPhoneError] = useState(null);
  const [acceptedCodeOfConduct, setAcceptedCodeOfConduct] = useState(false);
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactPhone, setEmergencyContactPhone] = useState("");
  const [emergencyContactRelationship, setEmergencyContactRelationship] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [specialNeedsCare, setSpecialNeedsCare] = useState(false);
  const [petFriendly, setPetFriendly] = useState("");
  const [elderlyCare, setElderlyCare] = useState(false);
  const [firstAidCertification, setFirstAidCertification] = useState(false);
  const [language, setLanguage] = useState([]);

  const [profileImage, setProfileImage] = useState(null);
  const [profileDescription, setProfileDescription] = useState("");

  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");

  const [profession, setProfession] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmergencyContactNameChange = (event) => {
    setEmergencyContactName(event.target.value);
  }
  const handleEmergencyContactPhoneChange = (event) => {
    setEmergencyContactPhone(event.target.value);
  }
  const handleEmergencyContactRelationshipChange = (event) => {
    setEmergencyContactRelationship(event.target.value);
  }
  const handleStreetChange = (event) => {
    setStreet(event.target.value);
  }
  const handleCityChange = (event) => {
    setCity(event.target.value);
  }
  const handleStateChange = (event) => {
    setState(event.target.value);
  }
  const handleNeighborhoodChange = (event) => {
    setNeighborhood(event.target.value);
  }
  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };
  const handlePetFriendlyChange = (e) => {
    setPetFriendly(e.target.value);
  };
  const handleProfileDescriptionChange = (e) => {
    setProfileDescription(e.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastName1Change = (event) => {
    setLastName1(event.target.value);
  };
  const handleLastName2Change = (event) => {
    setLastName2(event.target.value);
  };
  const handleNickNameChange = (event) => {
    setNickName(event.target.value);
  };
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleProfessionChange = (event) => {
    setProfession(event.target.value);
  };
  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  // Dialog Handle Functions
  const [open, setOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // Age Range Variables
  const ageRanges = [
    { label: "0 Años", value: 0 },
    { label: "1 Año", value: 1 },
    { label: "2 Años", value: 2 },
    { label: "3 Años", value: 3 },
    { label: "4 Años", value: 4 },
    { label: "5 Años", value: 5 },
    { label: "6 Años", value: 6 },
    { label: "7 Años", value: 7 },
    { label: "8 Años", value: 8 },
    { label: "9 Años", value: 9 },
    { label: "10 Años", value: 10 },
    { label: "11 Años", value: 11 },
    { label: "12 Años", value: 12 },
  ];
  
  // Languages 
  const languages = [
    "Español",
    "Inglés", 
    "Francés", 
    "Alemán",
    "Chino"
  ]

  const handleLanguageChange = (event) => {
    const {
      target: {value},
    } = event;
    setLanguage(
      typeof value === 'string' ? value.split(',') : value,
    )
    }

  const [from, setFrom] = useState(0);
  const [until, setUntil] = useState(0);

  const handleFromChange = (event) => {
    setFrom(event.target.value);
  };

  const handleUntilChange = (event) => {
    setUntil(event.target.value);
  };
  //----------------------------------

  let navigate = useNavigate();

  const handleRedirect = () => {
    handleCloseDialog();
    navigate("/");
  };
  //----------------------------------

  const [openFotoDialog, setHandleOpenFotoDialog] = useState(false);
  const handleOpenFotoDialog = () => {
    setHandleOpenFotoDialog(true);
  };
  const handleCloseFotoDialog = () => {
    setHandleOpenFotoDialog(false);
  };



  //Handle File Submit consts
  function fileSubmit(fileReference, file, userDocReference) {
    uploadBytes(fileReference, file).then(() => {
      getDownloadURL(fileReference).then((url) => {
        //Assign file url to user document
        updateDoc(userDocReference, {
          "profileImage": url,
        });
      });
    });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!profileImage) {
      setMessageTitle("Error en Imagen de Perfil");
      setMessageContent(
          "Selecciona una imagen diferente para tu perfil. Por favor, inténtalo de nuevo."
      );
      setIsSubmitting(false);
      console.info("Error in image.")
      handleOpenDialog();
      return; // Stop submission
    }
     // Check if the code of conduct checkbox is checked
    if (!acceptedCodeOfConduct) {
      setMessageTitle("Acepta el Código de Conducta");
      setMessageContent("Debes aceptar el Código de Conducta para proceder.");
      setIsSubmitting(false);
      handleOpenDialog();
      return; // Stop submission
    }
    // Validate date
    const today = new Date();
    const birthDate = new Date(date);
    const age = today.getFullYear() - birthDate.getFullYear();
    if (age < 18) {
      setDateError(true);
      console.info("Debes ser mayor de edad");
      setIsSubmitting(false);
      return;
    }

    setDateError(false);

    // Validate email
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      setIsSubmitting(false);
      console.info("Email inválido");
      return;
    }

    setEmailError(false);

    // Validate zip code
    const zipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
    if (!zipCodeRegex.test(zipCode)) {
      setZipCodeError(true);
      console.info("Código postal inválido");
      setIsSubmitting(false);
      return;
    }

    setZipCodeError(false);

    const phoneNumberRegex = /^\d{10}$/;
    if (!phoneNumberRegex.test(phoneNumber)) {
      setPhoneNumberError(true);
      console.info("Teléfono inválido");
      setIsSubmitting(false);
      return;
    }

    setPhoneNumberError(false);

    if(!phoneNumberRegex.test(emergencyContactPhone)){
      setEmergencyContactPhoneError(true);
      console.info("Teléfono de contacto de emergencia inválido");
      setIsSubmitting(false);
      return;
    }

    setEmergencyContactPhoneError(false);

    // Form is validated and ready to be submitted
    const formData = {
      firstName,
      lastName1,
      lastName2,
      nickName,
      date,
      email,
      phoneNumber,
      zipCode,
      serviceAgeFrom: from,
      serviceAgeUntil: until,
      specialNeedsCare,
      petFriendly,
      elderlyCare,
      firstAidCertification,  
      language,
      profession,
      profileDescription,
      emergencyContactName,
      emergencyContactPhone,
      emergencyContactRelationship,
      street,
      city,
      state,
      neighborhood,
    };

    try {
      // Call the Firebase Function to create a new user
      const response = await createNanny({ email, ...formData });
      const password = response.data.password;

      try {
        //Upload file to firebase storage
        const fileReference = ref(
          storage,
          `nanny/${response.data.uid}/profile_img`
        );
        const userDocReference = doc(db, "nanny", response.data.uid);
        fileSubmit(fileReference, profileImage, userDocReference);
        console.log("File uploaded");
      } catch (error) {
        setMessageTitle("No es posible subir la imagen");
        setMessageContent(
          "Ha ocurrido un error al subir la imagen. Por favor, inténtalo de nuevo más tarde. Error: " +
            error
        );
      }
      //Send Email
      sendEmail({
        email: email,
        templateId: "d-aad7a3a92b2a457f9618f6c3788e10cf",
      });

      // setMessages
      setMessageTitle("Usuario creado con éxito");
      setMessageContent(
        <>
          El usuario ha sido creado con éxito. El correo electrónico y la contraseña para iniciar sesión son: 
          <span style={{ fontWeight: 'bold', color: '#007BFF' }}>{email}</span>
          {" y "}
          <span style={{ fontWeight: 'bold', color: '#007BFF' }}>{password}</span>
          . Asegúrate de guardarlos en un lugar seguro. Tu correo y contraseña son los datos que necesitarás para iniciar sesión en un futuro.
        </>
      );
      //console.log(profileImageUrl)

      handleOpenDialog();
    } catch (error) {
      setMessageTitle("No es posible ejecutar la función de registro");
      setMessageContent(
        "Ha ocurrido un error al crear el usuario. Por favor, inténtalo de nuevo más tarde. Error: " +
          error
      );

      handleOpenDialog();
    }
    setIsSubmitting(false);
    // Submit the form
  };

  return (
    <>
      <div className="form-container">
        <form
          onSubmit={handleFormSubmit}
          style={{
            width: "400px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          }}
        >
          <Divider light style={{ marginBottom: "1rem" }}>
            <Chip label="Información Personal" />
          </Divider>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Nombre/s"
              value={firstName}
              onChange={handleFirstNameChange}
              error={false}
              helperText={false ? "Nombre requerido" : ""}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Apellido Paterno"
              value={lastName2}
              onChange={handleLastName2Change}
              error={false}
              helperText={false ? "Apellido requerido" : ""}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Apellido Materno"
              value={lastName1}
              onChange={handleLastName1Change}
              error={false}
              helperText={false ? "Apellido requerido" : ""}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              label="Nickname/Apodo"
              value={nickName}
              onChange={handleNickNameChange}
              error={false}
              helperText={false ? "Nickname requerido" : ""}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Fecha de Nacimiento"
              type="date"
              value={date}
              onChange={handleDateChange}
              error={dateError}
              helperText={dateError ? "Debes ser mayor de edad" : ""}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Correo Electrónico"
              type="email"
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailError ? "Correo inválido" : ""}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Teléfono Celular"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={phoneNumberError}
              helperText={phoneNumberError ? "Teléfono Invalido" : ""}
            />
          </FormControl>
          <Divider light style={{ marginBottom: "1rem" }}>
            <Chip label="Dirección" />
          </Divider>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Calle"
              value={street}
              onChange={handleStreetChange}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Colonia"
              value={neighborhood}
              onChange={handleNeighborhoodChange}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Ciudad"
              value={city}
              onChange={handleCityChange}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Estado"
              value={state}
              onChange={handleStateChange}
            />
          </FormControl>
          <FormControl
            className="form-control"
            style={{ marginBottom: "1rem" }}
          >
            <TextField
              required
              label="Código Postal"
              value={zipCode}
              onChange={handleZipCodeChange}
              error={zipCodeError}
              helperText={zipCodeError ? "Código Postal inválido" : ""}
            />
          </FormControl>
          <Divider light style={{ marginBottom: "1rem" }}>
            <Chip label="Datos de Servicio" />
          </Divider>
          <div style={{ marginBottom: "1rem" }}>Edad a Cuidar</div>
          <Stack direction="row" spacing={2} style={{ marginBottom: "1rem" }}>
            <div>
              <FormControl className="form-control" style={{ width: "140px" }}>
                <InputLabel id="from-label">Desde</InputLabel>
                <Select
                  labelId="from-label"
                  value={from}
                  onChange={handleFromChange}
                >
                  {ageRanges.map(({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl className="form-control" style={{ width: "140px" }}>
                <InputLabel id="until-label">Hasta</InputLabel>
                <Select
                  labelId="until-label"
                  value={until}
                  onChange={handleUntilChange}
                >
                  {ageRanges
                    .slice(
                      from > 0
                        ? ageRanges.findIndex((r) => r.value === from) + 1
                        : 0
                    )
                    .map(({ label, value }, index) => (
                      <MenuItem key={index} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </Stack>

          <FormControl style={{ marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={elderlyCare}
                  onChange={() => setElderlyCare(!elderlyCare)}
                  name="elderlyCare"
                  color="primary"
                />
              }
              label="¿Realiza cuidados a adultos mayores?"
            />
          </FormControl>

          <FormControl style={{ marginBottom: "2rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={specialNeedsCare}
                  onChange={() => setSpecialNeedsCare(!specialNeedsCare)}
                  name="specialNeedsCare"
                  color="primary"
                />
              }
              label="¿Realiza cuidado a niños con necesidades especiales?"
            />
          </FormControl>

          <FormControl fullWidth required style={{ marginBottom: "1rem" }}>
            <InputLabel id="allergy-label">
              ¿Eres alérgica a las mascotas?
            </InputLabel>

            <Select
              labelId="allergy-label"
              id="allergy-select"
              value={petFriendly}
              onChange={handlePetFriendlyChange}
            >
              <MenuItem value="Si">Si</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>

          <Divider light style={{ marginBottom: "1rem" }}>
            <Chip label="Experiencia" />
          </Divider>
          <ProfilePhotoDialog
            open={openFotoDialog}
            handleClose={handleCloseFotoDialog}
          />

          <div style={{ marginBottom: "1rem" }}>
            <p style={{ textAlign: "center" }}>
              Consulta las especificaciones de la foto de perfil:{" "}
              <Chip
                variant="contained"
                size="small"
                icon={<PhotoCameraIcon />}
                label="Ver especificaciones"
                onClick={handleOpenFotoDialog}
              />
            </p>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <label htmlFor="profile-image-input">
              <p>Ingresa tu foto de Perfil:</p>
              {profileImage ? profileImage.name : ""}
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfileImageChange}
              id="profile-image-input"
              required
            />
          </div>
          <TextField
            variant="outlined"
            label="Profesión"
            placeholder="Ej. Psicopedagogía, Enfermería, etc"
            value={profession}
            onChange={handleProfessionChange}
            fullWidth
            style={{ marginBottom: "1rem" }}
            required
          />

          <TextField
            variant="outlined"
            label="Descripción del Perfil"
            multiline
            placeholder="Describe el tipo de experiencia y menciona el tiempo que tienes cuidando bebés, niños y/o adultos mayores. Así como cursos, certificaciones y habilidades especiales."
            rows={4}
            value={profileDescription}
            onChange={handleProfileDescriptionChange}
            fullWidth
            style={{ marginBottom: "1rem" }}
            inputProps={{ maxLength: 200 }}
            required
          />
          <FormControl style={{ marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={firstAidCertification}
                  onChange={() =>
                    setFirstAidCertification(!firstAidCertification)
                  }
                  name="firstAidCertification"
                  color="primary"
                />
              }
              label="Cuento con certificado de primeros auxilios"
            />
          </FormControl>

          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">
              ¿Qué idiomas hablas?
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={language}
              onChange={handleLanguageChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="¿Qué idiomas hablas?"
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Divider light style={{ margin: "1rem" }}>
            <Chip label="Contacto de Emergencia" />
          </Divider>
          <FormControl style={{ marginBottom: "1rem" }} 
                        className="form-control">
            <TextField
              required
              label="Nombre"
              value={emergencyContactName}
              onChange={handleEmergencyContactNameChange}
              fullWidth
            />
          </FormControl>
          <FormControl style={{ marginBottom: "1rem" }}             className="form-control"
>
            <TextField
              required
              label="Teléfono"
              value={emergencyContactPhone}
              onChange={handleEmergencyContactPhoneChange}
              error={emergencyContactPhoneError}
              helperText={emergencyContactPhoneError ? "Teléfono Invalido" : ""}
              fullWidth
            />
          </FormControl>
          <FormControl style={{ marginBottom: "1rem" }}             className="form-control"
>
            <TextField
              required
              label="Parentesco"
              value={emergencyContactRelationship}
              onChange={handleEmergencyContactRelationshipChange}
            />
          </FormControl>
          <FormControl required style={{ marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptedCodeOfConduct}
                  onChange={(e) => setAcceptedCodeOfConduct(e.target.checked)}
                  name="acceptedCodeOfConduct"
                  color="primary"
                />
              }
              label={
                <span>
                  Aceptar el{" "}
                  <Link href="URL_TO_CODE_OF_CONDUCT" target="_blank">
                    Código de Conducta
                  </Link>
                </span>
              }
            />
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            color="primary"
            className="submit-button"
            style={{ marginBottom: "1rem" }}
          >
            {isSubmitting ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              "Enviar"
            )}
          </Button>
        </form>
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{messageTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {messageContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRedirect} autoFocus>
              Finalizar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const ProfilePhotoDialog = ({ open, handleClose }) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} style={{ borderRadius: 20 }}>
        
        <DialogContent>
          <DialogContentText>Foto tipo pasaporte, NO SELFIE, del pecho y hasta arriba de la cabeza, dejar aire para que al editarla no se corte la cara. Con fondo blanco y liso. Uniforme de Nannapp, blusa blanca, azul o rosa. Sonrisa y maquillaje natural.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default TempForm;
